<template>
    <div class="auth-wrapper auth-v1 px-2">
        <b-modal
      id="modal-scrollable"
      scrollable
      title="使用條款（包括有關私隱政策，免責聲明及版權的條款）"
      size="lg"
      ok-title="關閉"
      ok-only
    >
     
    <div v-html="plan.html_tnc"></div>

    </b-modal>
      <div class="auth-inner py-2">
        <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-large
        rounded="sm"
      >
        


        <!-- Register v1 -->
        <b-card class="mb-0">
            <b-img thumbnail :src='plan.logo' fluid class="mb-2"/>
          
          <b-card-title class="mb-1">
            網上購買會籍
          </b-card-title>
          <b-card-text class="mb-2">
          </b-card-text>
  
          <!-- form -->
          <validation-observer ref="registerForm">
            <h4 class="brand-text text-primary ml-1" v-if="valid === false">Invalid Link</h4>
            <b-form v-if="insert === false && valid === true">
              <b-form-group
                label="商戶 Client Name"
                label-for="client"
              >
                <b-form-input
                  id="client_name"
                  v-model="plan.client_name"
                  trim
                  readonly
                />
  
              </b-form-group>
  
              <b-form-group
                label="會籍名稱  Membership Name"
                label-for="Membership Name"
              >
                <b-form-input
                  id="name"
                  v-model="plan.code"
                  trim
                  readonly
                />
  
              </b-form-group>

              <b-form-group
                :label="mlabel"
                label-for="Price"
              >
                <b-form-input
                  id="price"
                  style="color:red;font-weight: bold;"
                  v-model="plan.price"
                  trim
                  readonly
                />
  
              </b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="plan.status"
                name="checkbox-1"
              >
                我已閱讀及同意<b-link v-b-modal.modal-scrollable>使用條款（包括有關私隱政策，免責聲明及版權的條款）</b-link>

              </b-form-checkbox>
              <VueSignaturePad class="mt-1" ref="signaturePad" height="150px" :options="{ dotSize: 2, throttle: 16,backgroundColor: 'rgba(200,200,200,1)',onBegin, onEnd }"/>
              <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" class="mt-1" block @click="clear()" variant="outline-warning">
                清除簽名
            </b-button>
              <hr />
              
               <b-form-group
                 label="你的 whatsapp *接收單據用"
                 label-for="mobile"
               >
               <b-input-group>
                    <b-form-input placeholder="whatsapp number"  v-model="client.mobile"/>
                    <b-input-group-append>
                    <b-button type="button" variant="outline-success" @click="verify_code()">
                        接收驗證碼
                    </b-button>
                    </b-input-group-append>
                </b-input-group>
  
               </b-form-group>
               <b-form-group
                 label="驗證碼"
                 label-for="mobile"
               >
                 <b-form-input
                   id="card"
                   v-model="client.verify_code"
                   trim
                 />
  
               </b-form-group>
               <hr />
               <b-form-group
                 label="Credit Card Number"
                 label-for="card"
               >
                 <b-form-input
                   id="card"
                    v-mask="'#### #### #### ####'"
                   v-model="card.number"
                   trim
                 />
  
               </b-form-group>
  
             <b-form-group
               label="Expiry Date (YYYY-MM)"
               label-for="Expiry Date"
             >
               <b-form-input
                 id="expire"
                 v-mask="'####-##'"
                 v-model="card.expire"
                 trim
               />
  
             </b-form-group>
  
            <b-form-group
              label="CVC"
              label-for="cvc"
            >
              <b-form-input
                id="cvc"
                v-mask="'###'"
                v-model="card.cvc"
                trim
              />
  
            </b-form-group>
  
              <!-- checkbox -->
              <!--<b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group>
  
               -->
              <b-button
                variant="primary"
                block
                type="button"
                @click="submit"
              >
                Submit
              </b-button>
              <b-row class="mt-2">
              <b-col><b-img style="width:100%" :src="require('@/assets/images/svg/mastercard.svg')" /></b-col>
              <b-col><b-img style="width:100%"  :src="require('@/assets/images/svg/unionpay.svg')" /></b-col>
              <b-col><b-img style="width:100%"  :src="require('@/assets/images/svg/visa.svg')" /></b-col>
              <b-col><b-img style="width:100%"  :src="require('@/assets/images/svg/ae.png')" /></b-col>
            </b-row>
            </b-form>
          </validation-observer>
          <hr />
          <div class="text-center" style="font-size:10px;color:#888;">Power by CloudFit Pro</div>
        </b-card>
      <!-- /Register v1 -->
       </b-overlay>
      </div>
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BCard, BLink, BCardTitle, BCardText, BForm,BImg,BRow,BCol,BFormTextarea,
    BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,BOverlay
  } from 'bootstrap-vue'
  import router from '@/router'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  export default {
    components: {
      VuexyLogo,
      BImg,BRow,BCol,
      // BSV
      BCard,
      BLink,
      BCardTitle,
      router,
      BCardText,
      BForm,
      BButton,
      BOverlay,
      BFormInput,
      BFormGroup,
      BFormTextarea,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      // validations
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    watch: {
    "card.number": {
      handler(newValue, oldValue) {
        console.log(newValue.substring(0,1))
        switch (newValue.substring(0,1)) {
          case "3":
            this.credit_card_format = '#### ###### #####'
            this.cvc_format = '####'
            break;
          default:
          this.credit_card_format = '#### #### #### ####'
            this.cvc_format = '###'
        }
      }
    }
  },
    data() {
      return {
        regEmail: '',
        username: '',
        member: null,
        loading: false,
        valid : true,
        password: '',
        status: '',
        insert: false,
        mlabel: '價錢 Price',
        client : {
            name: null,
            mobile: null,
            signature: null,
            verify_code: null,
        },
        plan : {
          code: null,
          time_limit: null,
          price: null,
          status:null,
          period_quantity: null,
          period_unit: null, 
          uuid: null
        },
        card: {
          number : null,
          expire : null,
          cvc: null,
        },
        // validation rules
        required,
        email,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
    },
    created() {
      this.get_plan_detail()
      if (this.$route.query.member !== null) {
        this.member = this.$route.query.member
      }
    },
    methods: {
        get_plan_detail() {
        this.$http.get(process.env.VUE_APP_API_BASE+`/plan/`+ router.currentRoute.params.plan_uuid + `/`)
          .then(res => {
            if (res.data.result === false ) {
              this.valid = false
            } else {
              this.plan = res.data.plan
              if (this.plan.first_month_type == 'pro-rata') { this.mlabel += ' (按比例收取)'}
            }
          })
      },
      clear() {
        this.$refs.signaturePad.clearSignature();
      },
      onBegin() {
        console.log('=== Begin ===');
      },
      onEnd() {
        console.log('=== End ===');
      },
      submit() {
      this.loading = true
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      this.client.signature = data
      this.$http.post(process.env.VUE_APP_API_BASE+`/paylink/membership/${this.member}/`, {
        card : this.card,
        client : this.client,
        plan : this.plan,
        "method" : "paylink",
        "type" : "membership"
      })
        .then(res => {
          if (res.data.result === true) {
            this.valid = false
            this.loading = false
            this.$swal({
              title: '已成功購買會籍',
              html: '我們亦會用WhatsApp傳送收據及合約給你.<br><br>',
              icon: 'success',
              showConfirmButton : false,
              buttonsStyling: false,
              backdrop: true,
            })
          } else {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    },
    verify_code() {
      this.loading = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/whatsapp/verify_code/`, {
        mobile : this.client.mobile
      })
        .then(res => {
          if (res.data.result === true) {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Success',
                icon: 'XCheck',
                variant: 'success',
                text: res.data.message,
              },
            })
          } else {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: res.data.message,
              },
            })
          }
        })
    }
    }
  }
  
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  </style>
  